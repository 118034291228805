import { Col, Grid, Row } from "react-bootstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import StrataDocumentsModal from "../pages/documents/strata-documents-modal"
import React, { useState } from "react"

const AreasWeServeBody = (props) => {
  const [currentForm, setCurrentForm] = useState("");
  const rentalManagementProposalRequest = 'rentalManagementProposalRequest'
  const rentalManagementProposalRequestUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/11'
  const strataProposalRequest = 'strataProposalRequest'
  const strataProposalRequestUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/10'
  return (
    <Grid fluid className="half-section-container feature-section">
      <Row>
      <Col md={6} className="half-section container">
        <div className="parallax-half-section">
          <LazyLoadImage
            alt="banner"
            style={{ objectFit: "cover" }}
            className="areas-we-serve-image"
            src={props.data.image.file.url}
          />
        </div>
      </Col>
      <Col md={6} className="half-section blocktext" style={{ backgroundColor: 'rgba(250,250,250,0)' }}>
        <Row className="heading">
          <Col md={12}>
            <h2 className="no-p">{props.data.title}</h2>
          </Col>
        </Row>
        <Col className="custom-text-color-children last-section">
          {documentToReactComponents(props.data.description.json)}
        </Col>
        <div className="row center">
          <Row className="pt-4">
            <Col md={12}>
              {props.proposalRequest === "strata" &&
                <button
                  className="btn btn-outline btn-primary-1 text-uppercase parallax-button"
                  onClick={() => setCurrentForm(strataProposalRequest)}>
                  Strata Management Proposal Request
                  <StrataDocumentsModal
                    open={currentForm === strataProposalRequest}
                    openModal={setCurrentForm}
                    documentUrl={strataProposalRequestUrl}/>
                </button>
              }
              {props.proposalRequest === "rental" &&
              <button
                className="btn btn-outline btn-primary-1 text-uppercase parallax-button"
                onClick={() => setCurrentForm(rentalManagementProposalRequest)}>
                Rental Management Proposal Request
                <StrataDocumentsModal
                  open={currentForm === rentalManagementProposalRequest}
                  openModal={setCurrentForm}
                  documentUrl={rentalManagementProposalRequestUrl}/>
              </button>
              }

            </Col>
          </Row>
        </div>
      </Col>
      </Row>
    </Grid>
  )
}

export default AreasWeServeBody;